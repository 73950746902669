import React from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Type.module.scss'

import Element from '../Element'

export const sizes = [
  'display0',
  'display1',
  'display2',
  'display3',
  'title-large',
  'title',
  'title-small',
  'subtitle',
  'subtitlebold',
  'base',
  'base-large',
  'small'
]

const Type = ({
  children,
  className,
  as,
  size,
  tight,
  color,
  bold,
  italic,
  upperFirst
}) => (
  <Element
    as={as}
    className={classNames(
      styles.Type,
      size && styles[size],
      tight && styles.tight,
      color && styles[`color-${color}`],
      bold && styles.bold,
      italic && styles.italic,
      upperFirst && styles.upperFirst,
      className
    )}
  >
    {children}
  </Element>
)

Type.displayName = 'Type'

Type.defaultProps = {
  as: 'div',
  size: 'base'
}

Type.propTypes = {
  children: node.isRequired,
  className: string,
  as: string,
  size: oneOf(sizes),
  tight: bool,
  color: string,
  bold: bool,
  italic: bool,
  upperFirst: bool
}

export default Type
